var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"visible":_vm.visible,"title":"新增","confirmLoading":_vm.confirmLoading,"destroyOnClose":true,"maskClosable":false},on:{"cancel":_vm.handleCancel,"ok":_vm.handleConfirm}},[_c('a-form',{attrs:{"form":_vm.dataForm,"label-col":{ span: 6 },"wrapper-col":{ span: 18 }}},[_c('a-form-item',{attrs:{"label":"任务编号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'number',
            {
              initialValue: 'MT' + _vm.moment().format('YYYYMMDDHHmmss'),
              rules: [
                { required: true, message: '请输入任务编号' },
                { max: 32, message: '超出最大长度(32)' },
              ],
            },
          ]),expression:"[\n            'number',\n            {\n              initialValue: 'MT' + moment().format('YYYYMMDDHHmmss'),\n              rules: [\n                { required: true, message: '请输入任务编号' },\n                { max: 32, message: '超出最大长度(32)' },\n              ],\n            },\n          ]"}],attrs:{"allowClear":true}})],1),_c('a-form-item',{attrs:{"label":"生产线"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'production_line',
            {
              rules: [{ required: true, message: '请选择生产线' }],
            },
          ]),expression:"[\n            'production_line',\n            {\n              rules: [{ required: true, message: '请选择生产线' }],\n            },\n          ]"}],staticStyle:{"width":"100%"}},[_c('a-select-option',{attrs:{"value":"a"}},[_vm._v("名创 A")]),_c('a-select-option',{attrs:{"value":"b"}},[_vm._v("名创 B")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }